/* src/components/Countdown.module.css */
.countdown {
    text-align: center;
    padding: 20px;
}

.title {
    font-family: 'Waltograph', cursive;
    font-size: 2.5rem;
    color: #ffd700; /* Gold text */
    text-shadow: 2px 2px #2a2a72; /* Adds depth */
    margin-bottom: 20px;
}

.time {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; /* Wrap elements if they don’t fit */
    gap: 10px; /* Add spacing between items */
    font-size: 1.5rem;
    color: #ffffff; /* White text for contrast */
    font-family: 'Waltograph', cursive;
    text-shadow: 1px 1px #000000;
}

.time span {
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    color: #2a2a72; /* Deep blue */
    text-align: center;
    min-width: 100px; /* Ensure consistent size */
}

/* No need for extra media queries since wrapping handles responsiveness */
/* Lock Image Styling */
.lockImage {
    position: absolute;
    top: 10%; /* Position lock at the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    width: 4rem; /* Adjust size as needed */
    height: auto;
    filter: invert(1); /* Invert colors to make black image white */
    opacity: 0.8; /* Optional: Slight transparency */
}
