/* src/styles/PostTimerSection.module.css */
@font-face {
    font-family: 'Lubalin Graph';
    src: url('../assets/fonts/itc-lubalin-graph-extra-light-5876ce99b2316.otf') format('opentype'); /* Adjust path based on your folder structure */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lubalin Graph';
    src: url('../assets/fonts/itc-lubalin-graph-extra-light-5876ce99b2316.otf') format('opentype'); /* Adjust path based on your folder structure */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf') format('truetype'); /* Adjust path based on your folder structure */
    font-weight: normal;
    font-style: normal;
}

.postTimerContainer {
    height: 200vh; /* Combined height for both sections */
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scroll-behavior: smooth;
    margin: 0;
    scrollbar-width: none; /* Firefox */
}

.postTimerContainer::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
}

.endSection {
    height: 100vh; /* Full viewport height for the title section */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    text-align: center;
}

.title {
    font-family: 'Waltograph', cursive;
    font-size: 2.5rem;
    color: #dadada; /* Gold text */
    text-shadow: 2px 2px #2a2a72;
}

.arrowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.hint {
    font-size: 1rem;
    color: #ffffff;
    text-shadow: 1px 1px #000000;
    margin-bottom: 10px;
    animation: fadeIn 1.5s infinite;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire screen */
}

/* Adjustments for smaller screens */
/*@media (max-width: 768px) {*/
/*    .video {*/
/*        width: 130%; !* Increase overflow on smaller screens *!*/
/*        transform: translateX(-10%); !* Center the animation more *!*/
/*        overflow: hidden;*/
/*    }*/
/*}*/

/*@media (max-width: 480px) {*/
/*    .video {*/
/*        width: 130%; !* Further increase overflow for very small screens *!*/
/*        transform: translateX(-10%); !* Further shift to keep the animation centered *!*/
/*        overflow: hidden;*/
/*    }*/
/*}*/

@keyframes pulse {
    0% {
        transform: translateY(0) rotate(45deg);
        opacity: 1;
    }
    50% {
        transform: translateY(10px) rotate(45deg);
        opacity: 0.7;
    }
    100% {
        transform: translateY(0) rotate(45deg);
        opacity: 1;
    }
}



@keyframes fadeIn {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}

.messageSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    text-align: center;
    padding: 20px 20px 700px 20px;
}

.message {
    font-size: 1.2rem;
    color: #ffffff; /* White text */
    text-shadow: 1px 1px #000000;
    line-height: 1.5;
    margin: 50px 20px;
}

/* Credits container */
/*.creditsContainer {*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    width: 100%;*/
/*    text-align: center;*/
/*    animation: scrollCredits 60s linear forwards;*/
/*    background: linear-gradient(to bottom, #000428, #004e92); !* Disney-style gradient *!*/
/*    padding: 20px 0;*/
/*}*/

/* Typography styles */
.subtitle {
    font-family: 'Lubalin Graph', sans-serif; /* Subtitles font */
    font-size: 1.1rem;
    font-weight: bold;
    margin: 30px 0 10px;
    color: #ffd700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.bodyText {
    font-family: 'Playfair Display', serif; /* Elegant font for body text */
    font-size: 1rem;
    line-height: 1.8;
    max-width: 80%;
    margin: 0 auto 40px auto;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    text-align: justify;
}

/* Animation for scrolling credits */
@keyframes scrollCredits {
    0% {
        top: 100%;
    }
    100% {
        top: -200%; /* Scroll beyond the viewport */
    }
}
