

@font-face {
  font-family: 'Waltograph';
  src: url('./assets/fonts/waltographUI.ttf') format('truetype');
}


/* src/App.css */
.app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #001d2b, #0097a2) fixed; /* Disney-inspired gradient */
  color: #ffffff;
  text-align: center;
  font-family: Arial, sans-serif;
  overflow: hidden; /* Allows scrolling */
}

.app::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.end-section h1 {
  font-family: 'Waltograph', cursive;
  font-size: 2.5rem;
  color: #ffd700;
  text-shadow: 2px 2px #2a2a72;
}

.end-section p {
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 1px 1px #000000;
  margin-top: 20px;
  line-height: 1.5;
}

/* No need for extra media queries since wrapping handles responsiveness */
/* Lock Image Styling */
.lockImage {
  position: absolute;
  top: 10%; /* Position lock at the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  width: 4rem; /* Adjust size as needed */
  height: auto;
  filter: invert(1); /* Invert colors to make black image white */
  opacity: 0.8; /* Optional: Slight transparency */
}
